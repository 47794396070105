import 'date-fns';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { IGenerateInvoiceModal } from '../../../interfaces/modals';
import {
  alpha,
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  ThemeOptions,
  useTheme,
} from '@material-ui/core';
import 'moment/locale/uk';
import { COLORS } from '../../../values/colors';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import classnames from 'classnames';
import { generateInvoiceRequest } from '../../../store/actions/invoice.actions';
import { DefinedRange, DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { inputDateRanges, staticDateRanges } from '../../../utils/dateRanges';
import { IDateRange } from '../../../interfaces/IDate';
import ReactDatePicker from 'react-datepicker';
import dateToUTCWithoutTime from '../../../utils/dateToUtcWithoutTime';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface GenerateInvoiceProps {
  dispatch: AppDispatch;
  modalData: IGenerateInvoiceModal;
}

const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      'rangeListDark': {
        'borderRight': '1px solid #595959',
        'overflow': 'hidden',
        '& .rdrStaticRanges': {
          '& .rdrStaticRange': {
            position: 'relative',
            borderColor: '#595959',
            color: COLORS.primaryLight,
            cursor: 'pointer',
          },
          '& .rdrStaticRange.rdrStaticRangeSelected': {
            '& .rdrStaticRangeLabel': {
              'color': COLORS.darkGreen,
              '&:hover': { background: '#212121' },
            },
          },
          '& .rdrStaticRange .rdrStaticRangeLabel': {
            'position': 'relative',
            'z-index': 10,
          },
          '& span.rdrStaticRangeLabel': {
            'position': 'relative',
            'z-index': 1,
            '&:hover': {
              background: '#212121',
            },
          },
        },
      },
      'rangeListLight': {},
      'rangeList': {
        'background': 'transparent',
        '& .rdrInputRanges': {
          '& .rdrInputRange': {},
        },
        '& .rdrStaticRanges': {
          '& .rdrStaticRange': {
            background: 'transparent',
          },
          '& .rdrStaticRangeSelected': {
            '& .rdrStaticRangeLabel': {
              color: COLORS.primaryGreen,
            },
          },
        },
      },
      'radio': {
        '&$checked': {
          color: COLORS.primaryGreen,
        },
        '&:hover': {
          backgroundColor: COLORS.secondaryOttoman,
        },
      },
      'radioDark': {
        '&$checked': {
          color: COLORS.darkGreen,
        },
        '&:hover': {
          backgroundColor: alpha(COLORS.darkGreen, theme.palette.action.hoverOpacity),
        },
      },
      'checked': {},
      'btnWrapper': {
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      'dialogHeadWrapper': {
        display: 'flex',
        justifyContent: 'spice-beetween',
        padding: '24px 16px',
      },
      'dialogHeadWrapperDark': {
        background: '#424242',
      },
      'dialogContent': {},
      'dialogContentLight': {},
      'dialogContentDark': { background: '#424242' },
      'invoice_title': {
        padding: '0',
      },
      'invoice_title__dark': {
        color: COLORS.darkGreen,
      },
      'invoice_title__light': {
        color: COLORS.primaryGreen,
      },
      'btn': {
        margin: '0 7px',
        borderRadius: '30px',
        width: '120px',
        padding: '6px 15px 6px 15px',
        color: COLORS.primaryLight,
      },
      'btnReset': {
        margin: 'auto 0 auto auto',
        borderRadius: '30px',
        width: '120px',
        padding: '6px 15px 6px 15px',
        color: COLORS.primaryLight,
      },
      'btnLight': {
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      'btnDark': {
        'backgroundColor': COLORS.darkGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryDarkGreen,
        },
      },
      'datePicker': {
        width: '130px',
        padding: '0.4375rem 0.75rem',
        border: '0',
        fontSize: '0.875rem',
        height: '30px',
        lineHeight: '1.5',
        borderRadius: '0.3rem',
        background: 'transparent',
        textAlign: 'center',
      },
      'datePickerLight': {
        'color': '#000000',
        'boxShadow': `1px 1px 5px 0px ${COLORS.primaryGreen}`,
        '&:focus': {
          outline: `2px solid ${COLORS.secondaryGreen}`,
        },
      },
      'datePickerDark': {
        'color': '#ffffff',
        'background': COLORS.darkGreen,
        'box-shadow': '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
        '&:focus': {
          outline: `2px solid ${COLORS.secondaryDarkGreen}`,
        },
        '&:hover': { background: COLORS.secondaryDarkGreen },
      },
      'datePickerContainer': {
        width: '310px',
        padding: '15px',
        backgroundColor: 'transparent',
        borderRadius: '3px',
        justifyContent: 'center',
        marginBottom: '5px',
      },

      'datePickerWrapper': {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        '&:not(:last-child)': {
          marginRight: '10px',
        },
        '& .react-datepicker-wrapper': {
          marginRight: '0',
        },
        '& .react-datepicker__day--selected ': {
          'background-color': COLORS.primaryGreen,
        },
        '& .rdrDayNumber': {
          'color': COLORS.primaryGreen,
          '& ::after': {
            color: COLORS.primaryGreen,
          },
        },
      },
      'datePickerWrapperDark': {
        '& .react-datepicker': {
          'background': '#595959',
          '& .react-datepicker__header': {
            background: COLORS.darkGreen,
          },
          '& .react-datepicker__day--selected': {
            background: COLORS.darkGreen,
          },
          '& react-datepicker__day--today': {
            background: COLORS.darkGreen,
          },
        },
        '&:focus': {
          borderColor: COLORS.secondaryDarkGreen,
        },
      },
      'datePickerWrapperLight': {},

      'calendarContainer': {
        background: 'transparent',
      },
      'calendarContainerLight': {
        '& .rdrMonths': {
          '& .rdrMonth': {
            '& .rdrDays': {
              '& .rdrDay': {
                '& .rdrDayNumber': {
                  '& ::after': {
                    background: COLORS.primaryGreen,
                  },
                },
                '& .rdrDayStartPreview .rdrDayEndPreview': {
                  borderColor: `${COLORS.primaryGreen}`,
                },
                '& .rdrDayStartPreview': {
                  borderColor: `${COLORS.primaryGreen}`,
                },
                '& .rdrDayInPreview': {
                  borderColor: `${COLORS.primaryGreen}`,
                },
                '& .rdrDayEndPreview': {
                  borderColor: `${COLORS.primaryGreen}`,
                },
                '& .rdrEndEdge': {
                  background: COLORS.primaryGreen,
                },
                '& .rdrStartEdge': {
                  background: COLORS.primaryGreen,
                },
                '& .rdrInRange': {
                  background: COLORS.primaryGreen,
                },
              },
            },
          },
        },
      },

      'calendarContainerDark': {
        '& .rdrMonthAndYearWrapper': {
          '& .rdrNextPrevButton.rdrNextButton i': {
            borderColor: 'transparent transparent transparent #ffffff',
          },
          '& .rdrNextPrevButton.rdrPprevButton i': {
            borderColor: 'transparent #ffffff transparent transparent',
          },
          '& .rdrNextPrevButton': {
            'background': COLORS.darkGreen,
            'boxShadow': '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
            '&:hover': {
              background: COLORS.secondaryDarkGreen,
            },
          },
        },
        '& .rdrMonthAndYearPickers': {
          '& .rdrMonthPicker': {
            '& select': {
              'color': '#ffffff',
              'background': `url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%23FFFFFF' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat center`,
              'backgroundPosition': `right 8px center`,
              '& option': {
                color: '#000000',
              },
            },
          },
          '& .rdrYearPicker': {
            '& select': {
              'color': '#ffffff',
              'background': `url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%23FFFFFF' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat center`,
              'backgroundPosition': `right 8px center`,
              '& option': {
                color: '#000000',
              },
            },
          },
        },
        '& .rdrMonths': {
          '& .rdrMonth': {
            '& .rdrWeekDays': {
              '& .rdrWeekDay': {
                color: '#ffffff',
              },
            },
            '& .rdrDays': {
              '& .rdrDayPassive': {
                '& .rdrDayNumber span': {
                  color: COLORS.darkGreen,
                },
              },
              '& .rdrDayDisabled.rdrDayWeekend': {
                'background': '#595959',
                '& .rdrDayNumber span': {
                  color: COLORS.darkGreen,
                },
              },
              '& .rdrDayToday:not(.rdrDayPassive)': {
                '& .rdrInRange ~ .rdrDayNumber span:after, & .rdrStartEdge ~ .rdrDayNumber span:after, & .rdrEndEdge ~ .rdrDayNumber span:after, & .rdrSelected ~ .rdrDayNumber span:after':
                  {
                    background: '#ffffff',
                  },
              },
              '& .rdrDay': {
                '& .rdrDayNumber span': {
                  color: '#ffffff',
                },
                '& .rdrDayNumber': {
                  '& ::after': {
                    background: COLORS.darkGreen,
                  },
                },
                '& .rdrDayStartPreview .rdrDayEndPreview': {
                  borderColor: `${COLORS.darkGreen}`,
                },
                '& .rdrDayStartPreview': {
                  borderColor: `${COLORS.darkGreen}`,
                },
                '& .rdrDayInPreview': {
                  borderColor: `${COLORS.darkGreen}`,
                },
                '& .rdrDayEndPreview': {
                  borderColor: `${COLORS.darkGreen}`,
                },
                '& .rdrEndEdge': {
                  background: COLORS.darkGreen,
                },
                '& .rdrStartEdge': {
                  background: COLORS.darkGreen,
                },
                '& .rdrInRange': {
                  background: COLORS.darkGreen,
                },
              },
            },
          },
        },
      },

      '@media (max-width: 660px)': {
        calendarContainer: {
          '& .rdrMonth': {
            width: '23.667em',
          },
        },
      },

      [theme.breakpoints.down('xs')]: {
        rangeList: {
          'display': 'flex',
          'flexDirection': 'row',
          'flexWrap': 'wrap',
          'justifyContent': 'center',
          'width': '100%',
          '& .rdrStaticRanges': {
            'flexDirection': 'row',
            '& .rdrStaticRangeLabel': {
              padding: '10px 16px',
            },
          },
        },
        calendarContainer: {
          '& .rdrMonth': {
            width: '27.667em',
          },
        },
        datePicker: {
          width: '120px',
        },
      },
      '@media (max-width: 534px)': {
        btn: {
          'marginBottom': '10px',

          '&:last-child ': {
            marginBottom: '0',
          },
        },
      },
      '@media (max-width: 420px)': {
        dialogContent: {
          padding: '16px 16px',
        },
        calendarContainer: {
          '& .rdrMonth': {
            width: '25.667em',
          },
        },
        btnWrapper: {
          margin: '10px 0',
        },
        datePicker: {
          'marginRight': '5px',
          'width': '115px',

          '&:last-child ': {
            marginRight: '0',
          },
        },
      },
      '@media (max-width: 376px)': {
        calendarContainer: {
          '& .rdrMonth': {
            width: '23.667em',
          },
        },
        dialogContent: {
          padding: '13px 13px',
        },
        btn: {
          marginLeft: '5px',
          width: '115px',
        },
        datePicker: {
          width: '112px',
        },
      },
    })
);

const FormDialog: React.FC<GenerateInvoiceProps> = ({ dispatch, modalData }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { darkMode } = useSelector((state: RootState) => state.theme);

  const { handleClose, isOpened } = modalData;

  const [dateRange, setDateRange] = useState<IDateRange[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleChangeDateRange = (dateRange) => {
    setDateRange(dateRange);
  };

  const reset = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const submit = () => {
    const utcStartDate = dateToUTCWithoutTime(dateRange[0].startDate);
    const utcEndDate = dateToUTCWithoutTime(dateRange[0].endDate);

    dispatch(generateInvoiceRequest({ startDate: utcStartDate, endDate: utcEndDate }));
    handleClose();
    reset();
  };

  return (
    <div>
      <Dialog
        open={isOpened}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <div
          className={classnames(
            classes.dialogHeadWrapper,
            darkMode ? classes.dialogHeadWrapperDark : classes.dialogHeadWrapperLight
          )}
        >
          <DialogTitle
            className={classnames(
              classes.invoice_title,
              darkMode ? classes.invoice_title__dark : classes.invoice_title__light
            )}
            id="form-dialog-title"
          >
            Вибрати період формування інвойсу
          </DialogTitle>
          <Button
            className={classnames(classes.btnReset, darkMode ? classes.btnDark : classes.btnLight)}
            variant="contained"
            onClick={reset}
          >
            Скинути
          </Button>
        </div>
        <DialogContent
          className={classnames(
            classes.dialogContent,
            darkMode ? classes.dialogContentDark : classes.dialogContentLight
          )}
          dividers
        >
          <Grid container justifyContent="center">
            <Grid container direction={isXsScreen ? 'column' : 'row'}>
              <Grid item style={{ width: isXsScreen ? '100%' : '44%' }}>
                <DefinedRange
                  className={classnames(
                    classes.rangeList,
                    darkMode ? classes.rangeListDark : classes.rangeListLight
                  )}
                  onChange={(item) => handleChangeDateRange([item.selection])}
                  ranges={dateRange}
                  // @ts-ignore
                  locale={locales.uk}
                  staticRanges={staticDateRanges}
                  inputRanges={inputDateRanges}
                />
              </Grid>
              <Grid item style={{ width: isXsScreen ? '100%' : '56%', overflow: 'visible' }}>
                <Grid
                  container
                  direction="row"
                  className={classnames(
                    classes.datePickerContainer,
                    darkMode ? classes.datePickerContainerDark : classes.datePickerContainerLight
                  )}
                >
                  <div
                    style={{
                      width: '45.5%',
                    }}
                    className={classnames(
                      classes.datePickerWrapper,
                      darkMode ? classes.datePickerWrapperDark : classes.datePickerWrapperLight
                    )}
                  >
                    <div>
                      <ReactDatePicker
                        dateFormat="dd-MM-yyyy"
                        selected={dateRange[0].startDate}
                        onChange={(item) =>
                          handleChangeDateRange([
                            { startDate: item, endDate: dateRange[0].endDate, key: dateRange[0].key },
                          ])
                        }
                        onClickOutside={() =>
                          !dateRange[0].startDate
                            ? handleChangeDateRange([
                                {
                                  startDate: new Date(),
                                  endDate: dateRange[0].endDate,
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        onKeyDown={() =>
                          !dateRange[0].startDate
                            ? handleChangeDateRange([
                                {
                                  startDate: new Date(),
                                  endDate: dateRange[0].endDate,
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        maxDate={
                          +dateRange[0].startDate === +dateRange[0].endDate
                            ? new Date()
                            : dateRange[0].endDate
                        }
                        locale={locales.uk}
                        className={classnames(
                          classes.datePicker,
                          darkMode ? classes.datePickerDark : classes.datePickerLight
                        )}
                      ></ReactDatePicker>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '45.5%',
                    }}
                    className={classnames(
                      classes.datePickerWrapper,
                      darkMode ? classes.datePickerWrapperDark : classes.datePickerWrapperLight
                    )}
                  >
                    <div>
                      <ReactDatePicker
                        dateFormat="dd-MM-yyyy"
                        selected={dateRange[0].endDate}
                        onChange={(item) =>
                          handleChangeDateRange([
                            { startDate: dateRange[0].startDate, endDate: item, key: dateRange[0].key },
                          ])
                        }
                        onClickOutside={() =>
                          !dateRange[0].endDate
                            ? handleChangeDateRange([
                                {
                                  startDate: dateRange[0].startDate,
                                  endDate: new Date(),
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        onKeyDown={() =>
                          !dateRange[0].endDate
                            ? handleChangeDateRange([
                                {
                                  startDate: dateRange[0].startDate,
                                  endDate: new Date(),
                                  key: dateRange[0].key,
                                },
                              ])
                            : {}
                        }
                        maxDate={new Date()}
                        minDate={dateRange[0].startDate}
                        locale={locales.uk}
                        className={classnames(
                          classes.datePicker,
                          darkMode ? classes.datePickerDark : classes.datePickerLight
                        )}
                      ></ReactDatePicker>
                    </div>
                  </div>
                </Grid>
                <Grid container justifyContent="center" spacing={0} direction="row">
                  <DateRange
                    className={classnames(
                      classes.calendarContainer,
                      darkMode ? classes.calendarContainerDark : classes.calendarContainerLight
                    )}
                    onChange={(item) => handleChangeDateRange([item.selection])}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateRange}
                    showDateDisplay={false}
                    locale={locales.uk}
                    maxDate={new Date()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box className={classes.btnWrapper}>
              <Button
                className={classnames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                variant="contained"
                onClick={handleClose}
              >
                Скасувати
              </Button>
              <Button
                className={classnames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                variant="contained"
                onClick={submit}
              >
                Підтвердити
              </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FormDialog;
