import { INotification } from '../interfaces/INotification';
import isUserReadNotification from './is-user-read-notification';

const countUnreadNotifications = (notifications: INotification[], userId: number) =>
  notifications.reduce((acc, notification) => {
    const isUserReadNotificationValue = isUserReadNotification(notification, userId);

    if (!isUserReadNotificationValue) {
      return acc + 1;
    }

    return acc;
  }, 0);

export default countUnreadNotifications;
