import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { editDrawStart } from "../../store/actions/draw.actions";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { COLORS } from "../../values/colors";


interface IEditCurrentDraw {
   showHandler: () => void;
}

const EditCurrentDraw: React.FC<IEditCurrentDraw> = ({ showHandler }) => {

   const dispatch = useDispatch()

   const darkMode = useSelector((state: RootState) => state.theme.darkMode);
   const draw = useSelector((state: RootState) => state.drawsReducer )

   const [isShow, setShow] = useState(true)

   const useStyles = makeStyles({
      'formWrapper': {
         'display': 'flex',
         'flexDirection': 'column',
         'textAlign': 'center',
         'justifyContent': 'center',
      },
      'title': {
         'margin': '0 auto'
      },
      'buttonItem': {
         'width': '100%',
         'backgroundColor': COLORS.primaryGreen,
         'color': '#fff',
         'borderRadius': '30px',
         '&:hover': {
           backgroundColor: COLORS.secondaryGreen,
         },
      },
      'buttonItemDark': {
         'backgroundColor': COLORS.darkGreen,
         '&:hover': {
           backgroundColor: COLORS.secondaryDarkGreen,
         },
      },
      'button': {
         'margin': '0 auto',
         'width': '150px',
         'marginTop': '20px',
         'alignItems': 'center'
      },
      'label': {
          'marginBottom': '5px',
          'fontSize': '16px'
      },
      'field': {
         'margin': '0 auto',
         'width': '350px',
         'marginBottom': '25px',
         'fontSize': '18px',
         'borderRadius': '5px',
         'background': 'inherit',
         '&::placeholder': {
            'color': '#000',
            'opacity': '.9' /* Firefox */
         },
         '&::-ms-input-placeholder': { /* Edge 12 -18 */
            'color': '#000',
            'opacity': '.9'
         }
      },
      'fieldDark': {
         'color': '#fff',
         '&::-webkit-calendar-picker-indicator': {
            'filter': 'invert(1)',
             'marginRight': '5px !important'
         },
         '&::placeholder': {
            'color': '#fff',
            'opacity': '.9' /* Firefox */
         },
         '&::-ms-input-placeholder': { /* Edge 12 -18 */
            'color': '#fff',
            'opacity': '.9'
         }
      },
      'error': {
         'color': 'red',
         'marginTop': '-10px',
         'marginBottom': '10px',
      }
   })
   const styles = useStyles();
  
   const validationForm = Yup.object().shape({
      prizeFund: Yup.number()
         .required('Заповніть суму розіграшу'),
      dateRaffle: Yup.date()
         .typeError('Expected a value of type ${type} but got: ${value}') // ex: Expected a value of type date but got: Invalid Date
         .required('Заповніть дату розіграшу'),
      conditions: Yup.string()
         .required('Коментар є обовʼязковим'),
   });

   return (
      <div>
         <Dialog
         open={isShow}
         onClose={showHandler}
         aria-labelledby="form-dialog-title"
         fullWidth
         maxWidth="xs"
         >
         <DialogTitle id="form-dialog-title" className={styles.title}>Редагування умов розіграшу:</DialogTitle>
         <DialogContent dividers>
            <Formik
               initialValues={{
                  prizeFund: draw?.prizeFund,
                  dateRaffle: draw?.dateRaffle,
                  conditions: draw?.conditions,
                  id: draw?.id
               }}
               validationSchema={validationForm}
               onSubmit={values => {
                  if(values.conditions && values.dateRaffle && values.prizeFund) {
                     dispatch(editDrawStart(values))
                  }
                  showHandler()
               }}
            >

            {({ errors, touched }) => (
            <Form className={styles.formWrapper}>
               
               <label htmlFor="prizeFund" className={styles.label}>Сума, грн: </label>
               <Field 
                  name="prizeFund" 
                  type='number' 
                  className={`${styles.field} ${darkMode && styles.fieldDark}`} 
                  placeholder="250"
               />
               {errors.prizeFund && touched.prizeFund ? (<div className={styles.error}>{errors.prizeFund}</div>) : null}

               <label htmlFor="dateRaffle" className={styles.label}>Дата: </label>
               <Field 
                  name="dateRaffle" 
                  type='Date' 
                  placeholder="дата розіграшу" 
                  className={`${styles.field} ${darkMode && styles.fieldDark}`}
               />
               {errors.dateRaffle && touched.dateRaffle ? (<div className={styles.error}>{errors.dateRaffle}</div>) : null}

               <label htmlFor="conditions" className={styles.label}>Коментар: </label>
               <Field 
                  name="conditions" 
                  type="text" 
                  placeholder="Buy-All Store" 
                  className={`${styles.field} ${darkMode && styles.fieldDark}`}
               />
               {errors.conditions && touched.conditions ? <div className={styles.error}>{errors.conditions}</div> : null}
               
               <Button
                  className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
                  variant="contained"
                  color="primary"
                  type="submit"
               >
                  Застосувати
               </Button>
            </Form>
         )}
            </Formik>
         </DialogContent>
         </Dialog>
      </div>
   )
}

export default EditCurrentDraw