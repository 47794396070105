import { FormControl, Select, MenuItem, TextField, Button } from '@material-ui/core';
import Avatar, { ConfigProvider } from 'react-avatar';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import {
  updateProfileUserReq,
  deleteAvatarRequest,
  addAvatarRequest,
  signOutUser,
} from '../../store/actions/user.action';
import { failSnackBar } from '../../store/actions/snackbar.actions';
import { IRole } from '../../interfaces/IRoles';
import { RootState } from '../../store/store';
import useRoles from '../../hooks/useRoles';
import { root } from '../../api/config';
import s from './Profile.module.scss';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LogoutDialog from '../../components/Modals/LogoutDialog/LogoutDialog';
import useWindowDimension from '../../hooks/useWindowDimension';
import AvatarEditModal from '../../components/Modals/AvatarEditModal/AvatarEditModal';

export default function Profile() {
  const dispatch = useDispatch();
  const { roles } = useRoles();
  const { user, avatarLink } = useSelector((state: RootState) => state.user);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isAvatarEditModalOpen, setIsAvatarEditModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<any>(null);

  const { width: screenWidth } = useWindowDimension();

  const userAvatar = user ? (!avatarLink ? null : user?.avatar?.name) : null;
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const phoneRegExp = /\+\d{3} \d{2} \d{3} \d{2} \d{2}/;
  const avatarSize = '150';
  const inputs = [
    { title: 'Призвіще', name: 'lastName', type: 'text', fullWidth: false },
    { title: "Ім'я", name: 'firstName', type: 'text', fullWidth: false },
    { title: 'День народження', name: 'dateOfBirth', type: 'date', fullWidth: false },
    { title: 'Номер Телефону', name: 'phoneNumber', type: 'tel', fullWidth: false },
    { title: 'Пошта', name: 'email', type: 'email', fullWidth: true },
    { title: 'Telegram', name: 'telegramId', type: 'text', fullWidth: false },
  ];
  const initValue = {
    id: user!.id,
    lastName: user!.lastName,
    firstName: user!.firstName,
    phoneNumber: user!.phoneNumber,
    email: user!.email,
    telegramId: user!.telegramId,
    dateOfBirth: user!.dateOfBirth ? user!.dateOfBirth.split('T')[0] : undefined,
    roleId: user!.role.id,
  };

  useEffect(() => {
    formik.setValues(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSaveSubmit = (formikValues) => {
    dispatch(
      updateProfileUserReq({
        ...formikValues,
        currentEmail: user!.email,
        currentPhoneNumber: user!.phoneNumber,
      })
    );
    formik.resetForm();
    setIsBeingEdited(false);
    //Sing-out when change role to non-admin
    const newRoleId = formikValues.roleId;
    if (newRoleId !== 1)
      setTimeout(() => {
        dispatch(signOutUser());
      }, 1000);
  };
  const formik = useFormik({
    initialValues: initValue,
    validationSchema: yup.object({
      lastName: yup.string().max(30, 'Не більше 30 символів!').required("Обов'язкове поле!"),
      firstName: yup.string().max(20, 'Не більше 20 символів!').required("Обов'язкове поле!"),
      phoneNumber: yup
        .string()
        .matches(phoneRegExp, 'Приклад валідного номера телефону: +380 11 222 33 44')
        .min(17, 'Приклад валідного номера телефону: +380 11 222 33 44')
        .max(17, 'Приклад валідного номера телефону: +380 11 222 33 44'),
      email: yup.string().email('Некорректний email!'),
      telegramId: yup.string(),
      dateOfBirth: yup.date(),
      roleId: yup.number().required(),
    }),
    onSubmit: onSaveSubmit,
  });

  const getFormattedTelegramId = (telegramId: string): string => {
    let newTelegramId = '@';
    for (let char of telegramId) {
      if (/\w/g.test(char)) newTelegramId += char;
    }
    return newTelegramId;
  };

  const customOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const cursorPos = e.target.selectionEnd;
    let value = e.target.value;
    if (name === 'telegramId') {
      if (e.target.value.length === cursorPos) value = getFormattedTelegramId(value);
      //Trying to insert an invalid character in the middle of the string
      else if (/\W/g.test(value.slice(1))) value = getFormattedTelegramId(value);
    }
    const newValue = {
      ...formik.values,
      [name]: value,
    };
    formik.setValues(newValue);
  };
  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !user) {
      return;
    }

    const availableFormats = ['png', 'jpg', 'jpeg', 'gif'];
    const imageFormat = e.target.files![0].type.replace(/image\//, '');
    if (!availableFormats.includes(imageFormat)) {
      dispatch(failSnackBar('Підтримувані формати фото: png, jpg, jpeg, gif'));
      return;
    }
    const imageSize = e.target.files![0]?.size;
    if (imageSize > 10000000) {
      dispatch(failSnackBar('Розмір аватара перевищює 10 Мб'));
      return;
    }

    const image = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        setPreviewImage(event.target.result);
      }
    };

    reader.readAsDataURL(image);

    setIsAvatarEditModalOpen(true);
  };

  const onSave = async (avatar: FormData) => {
    dispatch(addAvatarRequest(avatar));
    setPreviewImage(null);
  };

  const onDeleteAvatar = () => {
    if (!avatarLink) {
      dispatch(failSnackBar('Ви не маете аватара.'));
      return;
    }
    dispatch(deleteAvatarRequest);
  };

  const handleOnEdited = () => {
    setIsBeingEdited(!isBeingEdited);
    if (isBeingEdited) formik.setValues(initValue);
  };

  const openLogoutDialog = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <div className={s.page}>
      <div className={s.leftSide}>
        {isBeingEdited ? (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className={s.cancelSave}>
              <Button type="submit" variant="contained" color="primary" size="small">
                Зберегти
              </Button>
              <Button onClick={handleOnEdited} variant="contained" color="secondary" size="small">
                Скасувати
              </Button>
            </div>
            {inputs.map((input, index) => {
              const { value, name, onBlur } = formik.getFieldProps(input.name);
              return (
                <div className={s.wrapper} key={`${input.title}_${index}`}>
                  <div className={s.grayWords}>{input.title}</div>
                  {input.type === 'tel' ? (
                    <InputMask
                      style={{ width: '290px' }}
                      value={value}
                      type={input.type}
                      name={input.name}
                      id="tel-field"
                      mask="+380\ 99 999 99 99"
                      placeholder="+380 __ ___ __ __"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                      helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    >
                      {(inputProps) => <TextField {...inputProps} />}
                    </InputMask>
                  ) : (
                    <TextField
                      size="small"
                      variant="standard"
                      type={input.type}
                      value={value}
                      name={name}
                      onBlur={onBlur}
                      onChange={customOnChange}
                      error={formik.touched[input.name] && Boolean(formik.errors[input.name])}
                      helperText={formik.touched[input.name] && formik.errors[input.name]}
                      fullWidth={input.fullWidth}
                    />
                  )}
                </div>
              );
            })}
            <div className={s.wrapper}>
              <div className={s.grayWords}>Роль</div>
              <FormControl>
                <Select name="roleId" value={formik.values.roleId} onChange={formik.handleChange}>
                  {roles.map((role: IRole, index: number) => (
                    <MenuItem key={`${role.name}_${index}`} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </form>
        ) : (
          <>
            <EditIcon onClick={handleOnEdited} className={s.editIcon} />
            {inputs.map((input, index) => (
              <div className={s.wrapper} key={`${input.title}_${index}`}>
                <div className={s.grayWords}>{input.title}</div>
                <div className={s.normalWords}>{formik.values[input.name]}</div>
              </div>
            ))}
            <div className={s.wrapper}>
              <div className={s.grayWords}>Роль</div>
              <div className={s.normalWords}>{user!.role.name}</div>
            </div>
          </>
        )}
        {screenWidth <= 960 && (
          <Button
            onClick={openLogoutDialog}
            startIcon={<ExitToAppIcon fontSize="small" />}
            variant="outlined"
          >
            Вийти
          </Button>
        )}
        {logoutModalOpen && <LogoutDialog closeModal={closeLogoutModal} />}
      </div>

      <div className={s.rightSide}>
        <div className={s.rightBody}>
          <div className={s.imageContainer}>
            <Delete onClick={onDeleteAvatar} className={s.deleteImg} />
            <label htmlFor="avatar" className={s.avatarOverlay}>
              <span>
                <AddAPhotoIcon style={{ color: 'white' }} />
              </span>
              <input id="avatar" type="file" onChange={onChangeAvatar} />
            </label>
            {user ? (
              userAvatar ? (
                <>
                  <Avatar
                    src={`${root}/static/uploads/avatar/${userAvatar}`}
                    alt="avatar"
                    round={true}
                    size={avatarSize}
                  />
                </>
              ) : (
                <ConfigProvider avatarRedirectUrl="https://avatar-redirect.appspot.com">
                  <Avatar
                    googleId={user?.googleId}
                    facebookId={user?.facebookId}
                    name={`${user.lastName} ${user.firstName}`}
                    round={true}
                    alt="avatar"
                    size={avatarSize}
                  />
                </ConfigProvider>
              )
            ) : null}
            {previewImage ? (
              <AvatarEditModal
                isAvatarEditModalOpen={isAvatarEditModalOpen}
                setIsAvatarEditModalOpen={setIsAvatarEditModalOpen}
                saveAvatar={onSave}
                previewImage={previewImage}
              />
            ) : null}
          </div>
          <div className={s.possibleImgParams}>
            <div className={s.possibleImgParamsText}>*Розмір фото не має перевищувати 10 Мб</div>
            <div className={s.possibleImgParamsText}>*Підтримувані формати фото: png, jpg, jpeg, gif</div>
          </div>
        </div>
      </div>
    </div>
  );
}
