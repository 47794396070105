enum NotificationTypeEnum {
  NEW_ORDER = 'NEW_ORDER',
}

enum NotificationDataStatus {
  PENDING = 'PENDING',
  IDLE = 'IDLE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export { NotificationTypeEnum, NotificationDataStatus };
