import { call, put } from 'redux-saga/effects';
import { apiGetAllNotification, apiReadNotification } from './services/notification.service';
import {
  getAllNotificationError,
  getAllNotificationSuccess,
  notificationReadSuccess,
} from '../actions/notifications.action';
import { SagaIterator } from 'redux-saga';
import { IActions } from '../../interfaces/actions';

export function* getAllNotificationsWorker(): SagaIterator<void> {
  try {
    const { data: notifications } = yield call(apiGetAllNotification);
    yield put(getAllNotificationSuccess(notifications));
  } catch (error: unknown) {
    console.error(error);
    yield put(getAllNotificationError());
  }
}

interface ReadNotificationWorkerArg extends IActions {
  data: number;
}

export function* readNotificationWorker({
  data: notificationId,
}: ReadNotificationWorkerArg): SagaIterator<void> {
  const { data: notification } = yield call(apiReadNotification, notificationId);
  yield put(notificationReadSuccess(notification));
}
