import React, { FC, useEffect, useState } from "react";
import EditCurrentDraw from "./EditCurrentDraw";
import SaveCurrentDraw from "./SaveCurrentDraw";
import DrawParticipants from "./DrawParticipants";
import DrawWinnerRandomizer from "./DrawWinnerRandomizer";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { createDrawStart, getActiveDrawStart, getDrawUsersByIdStart } from "../../store/actions/draw.actions";

import { Button, alpha, makeStyles } from "@material-ui/core";
import { COLORS } from "../../values/colors";



const useStyles = makeStyles({
   'wrapper': {
      'margin': '0 15px 25px 15px',
      'padding': '5px 15px',
      'display': 'flex',
      'flexDirection': 'row',
      'justifyContent': 'space-between',
      'alignItems': 'center',
      'border': '1px solid #000',
      'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
      'borderRadius': '5px',
      'fontSize': '18px'
   },
   'title': {
      'fontSize': '20px'
   },
   'item': {

   },
   'buttonItem': {
      'width': '100%',
      'backgroundColor': COLORS.primaryGreen,
      'borderRadius': '30px',
      '&:hover': {
        'backgroundColor': COLORS.secondaryGreen,
      },
    },
    'buttonItemDark': {
      'backgroundColor': COLORS.darkGreen,
      '&:hover': {
        'backgroundColor': COLORS.secondaryDarkGreen,
      },
    },
    'buttons': {
      'display': 'flex',
      'gap': '10px'
    },
    'button': {
      'color': COLORS.primaryLight,
      'width': 'auto',
      'marginTop': '20px',
      'marginBottom': '20px',
      'div': {
         'display': 'flex',
         'alignItems': 'center',
         'justifyContent': 'center'
      }
    },
    'newDrawButton': {
      'marginLeft': '15px'
    },
    'drawStatus': {
      'paddingTop': '25px',
      'paddingLeft': '15px'
    },
    switch: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        'color': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: alpha(COLORS.primaryGreen, .2),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: COLORS.primaryGreen,
      },
      'marginLeft': 'auto',
    },
    switchDark: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        'color': COLORS.darkGreen,
        '&:hover': {
          backgroundColor: alpha(COLORS.darkGreen, .5),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: COLORS.darkGreen,
      },
      'marginLeft': 'auto',
    },
    'randomizer': {
      'display': 'flex',
      'flexShrink': 2,
      'margin': '15px',
      'flexDirection': 'row',
      'justifyContent': 'space-around',
      'alignItems': 'flex-start',
      'fontSize': '18px'

    },
   })

const CurrentDraw: FC = () => {

  const dispatch = useDispatch()
  
  const draw = useSelector((state: RootState) => state.drawsReducer )
  const data = useSelector((state: RootState) => state.drawsReducer.drawUsers)
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const styles = useStyles();

  useEffect(() => {
    dispatch(getActiveDrawStart());
    dispatch(getDrawUsersByIdStart(draw.id))
  }, [dispatch, draw.id]);
  
  const [isEditForm, setEditForm] = useState(false)
  const editShowHandler = () => setEditForm(prev => !prev)

  // const [isActive, setIsActive] = useState(true)
  // const changeStatusDraw = () => setIsActive(prev => !prev)

  const [isSaveForm, setSaveForm] = useState(false)
  const saveShowHandler = () => setSaveForm(prev => !prev)

  const [result, setResult] = useState<number>(0)

  const winner = data?.filter(user => user.winner === true)[0]

  const date = new Date()
  date.setDate(date.getDate() + 7)
  const today = date.toISOString().split('T')[0];

  const createNewDraw = () => {

    const newDrawInitialValues = { 
      name: '', 
      dateRaffle: today, 
      prizeFund: '250', 
      conditions: 'BuyAllStore', 
      isActive: true 
    }

    dispatch(createDrawStart(newDrawInitialValues))
   }

  return (
      <div>
        {draw.isActive 
        ?
        <>
          <div className={styles.wrapper}>
            <span className={styles.title}>Поточний розіграш:</span>
            <span className={styles.item}>Сума: <br/> {draw?.prizeFund } грн.</span>      
            <span className={styles.item}>Дата: <br/> {draw?.dateRaffle}</span>            
            <span className={styles.item}>Коментар: <br/> {draw?.conditions}</span>      
            <div className={styles.buttons}>
               <Button
               className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
               variant="contained"
               type="button"
               onClick={editShowHandler}
               >
               Редагувати
               </Button>
            {/* </div> */}
            {/* <div>
               Прийом заявок: 
               <Switch
                  className={darkMode ? styles.switchDark : styles.switch}
                  checked={isActive}
                  onChange={changeStatusDraw}
               />
            </div> */}
            {/* <div className={styles.buttons}> */}
            <Button
               className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
               variant="contained"
               type="button"
               onClick={saveShowHandler}
               disabled={ winner ? false : true }
               >
               Зберегти
               </Button>
            </div>
            {isEditForm && <EditCurrentDraw showHandler={editShowHandler}/>}
            {isSaveForm && <SaveCurrentDraw showHandler={saveShowHandler}/>}
          </div>
          <div className={styles.randomizer}>
            <DrawWinnerRandomizer data={data} result={result} setResult={setResult}/>
            <DrawParticipants />
          </div>
        </>
        : 
        <Button
            className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark} ${styles.newDrawButton}`}
            variant="contained"
            type="button"
            onClick={createNewDraw}
            >
            Створити новий розіграш
        </Button>
      }
    </div>
  )
}

export default CurrentDraw