import React, { useEffect, useState } from 'react';
import DrawsButtons from './DrawsButtons';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getDrawStart } from '../../store/actions/draw.actions';

import DataTable from 'react-data-table-component';
import { Card, makeStyles } from '@material-ui/core';
import { number } from 'yup';
import { SearchResultHighlight } from '../Common/SearchResultHighlight';

interface IDataChart {
  date: string;
  value: string;
  valueSecond?: string;
}

enum cols {
  id = '№',
  dateRaffle = 'Дата',
  prizeFund = 'Сума',
  name = 'Переможець',
  conditions = 'Коментар',
}

const useStyles = makeStyles({
  wrapper: {
    margin: '25px 15px 35px 15px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: '1px solid #000',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
    borderRadius: '5px',
    fontSize: '18px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px 25px',
    padding: '0 10px 15px',
  },
  title: {
    fontSize: '20px',
    marginTop: '15px',
  },
  table: {
    width: '100%',
    padding: '5px',
  },
});

const TotalDraws: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.drawsReducer.completedDraws);
  const { searchValue } = useSelector((state: RootState) => state.drawsReducer);

  const regExp = searchValue ? new RegExp(searchValue, 'ig') : null;

  useEffect(() => {
    dispatch(getDrawStart());
  }, [dispatch, searchValue]);

  const classes = useStyles();
  const { darkMode } = useSelector((state: RootState) => state.theme);

  const [chartData, setChartData] = useState<IDataChart[] | null>(null);

  const drawsColumns = [
    {
      name: '№',
      cell: (row, index) => index + 1,
      grow: 0,
      maxWidth: '9%',
      minWidth: '7%',
    },
    {
      name: cols.dateRaffle,
      selector: (row) => row.dateRaffle,
      sortable: true,
      format: (row) => {
        return new Date(row.dateRaffle).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        });
      },
      cell: (row) => {
        let newData: React.JSX.Element | null = null;
        if (searchValue && regExp) {
          newData = <SearchResultHighlight data={row.dateRaffle} filter={searchValue} regExp={regExp} />;
        }

        return <span>{newData ? newData : row.dateRaffle}</span>;
      },
    },
    {
      name: cols.prizeFund,
      selector: (row) => row.prizeFund,
      sortable: true,
    },
    {
      name: cols.name,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: cols.conditions,
      selector: (row) => row.conditions,
      sortable: true,
    },
  ];

  // table custom styles
  const dataTable = {
    headCells: {
      style: {
        justifyContent: 'center',
        paddingLeft: '23px',
        fontSize: '14px',
        textAlign: 'center',
      },
    },
    cells: {
      style: {
        justifyContent: 'center',
      },
    },
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const enterStartDate = (dateStart) => setStartDate(dateStart);
  const enterEndDate = (dateEnd) => setEndDate(dateEnd);

  const filteredData = data?.filter((draw) => {
    const formatedStartDate = startDate!.toISOString().split('T')[0];
    const formatedEndDate = endDate!.toISOString().split('T')[0];

    return draw.dateRaffle >= formatedStartDate && draw.dateRaffle <= formatedEndDate;
  });

  const filterValue = data?.filter((item) => item.dateRaffle.includes(searchValue));

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <span className={classes.title}>Проведені розіграші:</span>
        <DrawsButtons enterStartDate={enterStartDate} enterEndDate={enterEndDate} />
      </div>

      <Card className={classes.table}>
        <DataTable
          customStyles={dataTable}
          //  data={filteredData?.length ? filteredData : data}
          data={searchValue ? filterValue : data}
          columns={drawsColumns}
          theme={darkMode ? 'dark' : 'default'}
          highlightOnHover={true}
          center
          pointerOnHover={true}
          pagination
          fixedHeader={true}
          fixedHeaderScrollHeight={'60vh'}
          paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100]}
        />
      </Card>
    </div>
  );
};

export default TotalDraws;
