import { IActions } from '../../interfaces/actions';
import {
  IProductItem,
  ICharValue,
  IGetProducts,
  IAddProduct,
  IUpdateProduct,
  IGetProductById,
  IUpdateAvailabilityProduct,
  IDisableProduct,
  IProductsFilter,
  IUpdateDiscountedPrice,
  IProductStatistic,
  IDiscountInfo,
  IShopList,
} from '../../interfaces/IProducts';
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID_ERROR,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR,
  UPLOAD_MAIN_IMG_REQUEST,
  UPLOAD_MAIN_IMG_SUCCESS,
  UPLOAD_MAIN_IMG_ERROR,
  UPDATE_AVAILABILITY_PRODUCT_REQUEST,
  UPDATE_AVAILABILITY_PRODUCT_SUCCESS,
  UPDATE_AVAILABILITY_PRODUCT_ERROR,
  DISABLE_PRODUCT_REQUEST,
  DISABLE_PRODUCT_SUCCESS,
  DISABLE_PRODUCT_ERROR,
  GET_PRODUCTS_SIZES_REQUEST,
  GET_PRODUCTS_SIZES_SUCCESS,
  GET_PRODUCTS_SIZES_ERROR,
  SET_PRODUCT_SIZE,
  RESET_FILTER_VALUES,
  UPDATE_PRODUCT_DISCOUNTED_PRICE_ERROR,
  UPDATE_PRODUCT_DISCOUNTED_PRICE_REQUEST,
  UPDATE_PRODUCT_DISCOUNTED_PRICE_SUCCESS,
  GET_PRODUCT_STATISTIC_REQUEST,
  GET_PRODUCT_STATISTIC_SUCCESS,
  GET_PRODUCT_STATISTIC_ERROR,
  GET_DISCOUNT_INFO_REQUEST,
  GET_DISCOUNT_INFO_SUCCESS,
  GET_DISCOUNT_INFO_ERROR,
  GET_SHOP_LIST_REQUEST,
  GET_SHOP_LIST_SUCCESS,
  GET_SHOP_LIST_ERROR,
  SET_PRODUCTS_FIRST_RENDER,
  GET_PRICE_RANGE_REQUEST,
  GET_PRICE_RANGE_SUCCESS,
  GET_PRICE_RANGE_ERROR,
} from '../types';

// get all
export const getProductsRequest = (
  page: number,
  limit: number,
  sort: string,
  sortDirect: string,
  filter: IProductsFilter
): IActions => ({
  type: GET_PRODUCTS_REQUEST,
  data: { page, limit, sort, sortDirect, filter },
});

export const getProductsSuccess = (products: IGetProducts[]): IActions => ({
  type: GET_PRODUCTS_SUCCESS,
  data: products,
});

export const getProductsError = (message: string): IActions => ({
  type: GET_PRODUCTS_ERROR,
  data: message,
});

export const setProductsFirstRender = (isRender: boolean): IActions => ({
  type: SET_PRODUCTS_FIRST_RENDER,
  data: isRender,
});

export const getPriceRangeRequest = (filter: object): IActions => ({
  type: GET_PRICE_RANGE_REQUEST,
  data: filter,
});

export const getPriceRangeSuccess = (priceRange): IActions => ({
  type: GET_PRICE_RANGE_SUCCESS,
  data: priceRange,
});

export const getPriceRangeError = (error): IActions => ({
  type: GET_PRICE_RANGE_ERROR,
  data: error,
});

// get one by id
export const getProductByIdRequest = (id: number): IActions => ({
  type: GET_PRODUCT_BY_ID_REQUEST,
  data: id,
});

export const getProductByIdSuccess = (product: IProductItem): IActions => ({
  type: GET_PRODUCT_BY_ID_SUCCESS,
  data: product,
});

export const getProductByIdError = (message: string): IActions => ({
  type: GET_PRODUCT_BY_ID_ERROR,
  data: message,
});

// add product
export const addProductRequest = (
  productValues: IAddProduct,
  characteristicValues: ICharValue[]
): IActions => ({
  type: ADD_PRODUCT_REQUEST,
  data: { productValues, characteristicValues },
});

export const addProductSuccess = (product: IProductItem): IActions => ({
  type: ADD_PRODUCT_SUCCESS,
  data: product,
});

export const addProductError = (message: string): IActions => ({
  type: ADD_PRODUCT_ERROR,
  data: message,
});

// upload main image
export const uploadMainImgRequest = (productId: number, imgName: string): IActions => ({
  type: UPLOAD_MAIN_IMG_REQUEST,
  data: { productId, imgName },
});

export const uploadMainImgSuccess = (product: IGetProductById): IActions => ({
  type: UPLOAD_MAIN_IMG_SUCCESS,
  data: product,
});

export const uploadMainImgError = (message: string): IActions => ({
  type: UPLOAD_MAIN_IMG_ERROR,
  data: message,
});

// update product
export const updateProductRequest = (
  id: number,
  productValues: IUpdateProduct,
  characteristicValues: {
    charsToAdd: ICharValue[];
    charsToEdit: ICharValue[];
    charsToDelete: number[];
  },
  imagesToDelete: string[],
  imagesToReorder?: string[]
): IActions => ({
  type: UPDATE_PRODUCT_REQUEST,
  data: { id, productValues, characteristicValues, imagesToDelete, imagesToReorder },
});

export const updateProductSuccess = (product: IProductItem): IActions => ({
  type: UPDATE_PRODUCT_SUCCESS,
  data: product,
});

export const updateProductError = (message: string): IActions => ({
  type: UPDATE_PRODUCT_ERROR,
  data: message,
});

// update availability product
export const updateAvailabilityProductRequest = (data: IUpdateAvailabilityProduct): IActions => ({
  type: UPDATE_AVAILABILITY_PRODUCT_REQUEST,
  data: data,
});

export const updateAvailabilityProductSuccess = (product: IProductItem): IActions => ({
  type: UPDATE_AVAILABILITY_PRODUCT_SUCCESS,
  data: product,
});

export const updateAvailabilityProductError = (message: string): IActions => ({
  type: UPDATE_AVAILABILITY_PRODUCT_ERROR,
  data: message,
});

// disable product
export const disableProductRequest = (data: IDisableProduct): IActions => ({
  type: DISABLE_PRODUCT_REQUEST,
  data,
});
export const disableProductSuccess = (product: IProductItem): IActions => ({
  type: DISABLE_PRODUCT_SUCCESS,
  data: product,
});
export const disableProductError = (message: string): IActions => ({
  type: DISABLE_PRODUCT_ERROR,
  data: message,
});

// update discounted prise of product
export const updateProductDiscountedPriceRequest = (data: IUpdateDiscountedPrice): IActions => ({
  type: UPDATE_PRODUCT_DISCOUNTED_PRICE_REQUEST,
  data,
});
export const updateProductDiscountedPriceSuccess = (product: IProductItem): IActions => ({
  type: UPDATE_PRODUCT_DISCOUNTED_PRICE_SUCCESS,
  data: product,
});
export const updateProductDiscountedPriceError = (message: string): IActions => ({
  type: UPDATE_PRODUCT_DISCOUNTED_PRICE_ERROR,
  data: message,
});

// delete product
export const deleteProductRequest = (product: IGetProductById): IActions => ({
  type: DELETE_PRODUCT_REQUEST,
  data: product,
});

export const deleteProductSuccess = (id: number): IActions => ({
  type: DELETE_PRODUCT_SUCCESS,
  data: id,
});

export const deleteProductError = (message: string): IActions => ({
  type: DELETE_PRODUCT_ERROR,
  data: message,
});

export const getProductsSizesRequest = (): IActions => ({
  type: GET_PRODUCTS_SIZES_REQUEST,
  data: null,
});

export const getProductsSizesSuccess = (productsSizes: string[]): IActions => ({
  type: GET_PRODUCTS_SIZES_SUCCESS,
  data: productsSizes,
});

export const getProductsSizesError = (message: string): IActions => ({
  type: GET_PRODUCTS_SIZES_ERROR,
  data: message,
});

export const setProductSize = (size: string): IActions => ({
  type: SET_PRODUCT_SIZE,
  data: size,
});

export const resetFilterValues = (): IActions => ({
  type: RESET_FILTER_VALUES,
  data: null,
});

export const getProductStatisticRequest = (productId: number): IActions => ({
  type: GET_PRODUCT_STATISTIC_REQUEST,
  data: productId,
});

export const getProductStatisticSuccess = (statistic: IProductStatistic): IActions => ({
  type: GET_PRODUCT_STATISTIC_SUCCESS,
  data: statistic,
});

export const getProductStatisticError = (message: string): IActions => ({
  type: GET_PRODUCT_STATISTIC_ERROR,
  data: message,
});

export const getDiscountInfoRequest = (): IActions => ({
  type: GET_DISCOUNT_INFO_REQUEST,
});

export const getDiscountInfoSuccess = (discountInfo: IDiscountInfo): IActions => ({
  type: GET_DISCOUNT_INFO_SUCCESS,
  data: discountInfo,
});

export const getDiscountInfoError = (message: string): IActions => ({
  type: GET_DISCOUNT_INFO_ERROR,
  data: message,
});

export const getShopListRequest = (): IActions => ({
  type: GET_SHOP_LIST_REQUEST,
});

export const getShopListSuccess = (shops: IShopList[]): IActions => ({
  type: GET_SHOP_LIST_SUCCESS,
  data: shops,
});

export const getShopListError = (message: string): IActions => ({
  type: GET_SHOP_LIST_ERROR,
  data: message,
});
